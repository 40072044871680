import { BatchAction, CustomBatch } from "@/interfaces/batch";
import { PRODUCT_UNIT } from "@/interfaces/batchTransferManager";
import { Room } from "@/interfaces/room";
import { EventBus } from "@/internal";
import { ungroup } from "@/utils/convert.utils";
import { sumObjArrayBy } from "@/utils/math.utils";
import cloneDeep from "lodash/cloneDeep";
import omit from "lodash/omit";
import reduce from "lodash/reduce";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { Getter } from "vuex-class";
import BatchListSelecterComponent from "../common/BatchListSelecter/BatchListSelecter.component";
import {
  BatchQuantitiesManagerComponent,
  BatchQuantityManagerEvent
} from "../common/BatchQuantitiesManager/BatchQuantitiesManager.component";
import Template from "./Move.template.vue";
import { MovedListComponent } from "./MovedList/MovedList.component";

@Component({
  mixins: [Template],
  components: {
    BatchListSelecterComponent,
    BatchQuantitiesManagerComponent,
    MovedListComponent
  }
})
export default class MoveComponent extends Vue {
  public get previewMove() {
    return (
      (this.origin &&
        ungroup(omit(this.origin!.batches, Object.keys(this.data.batches)))) ||
      []
    );
  }

  public get currentModel() {
    return cloneDeep(this.data);
  }
  @Prop({ required: true })
  public data!: BatchAction<CustomBatch>;

  @Prop({ required: true })
  public origin!: BatchAction<CustomBatch>;

  @Prop({ default: true })
  public loading!: boolean;

  @Prop({ default: false })
  public reserve!: boolean;

  @Getter("bioTrackTraceEnabled", { namespace: "AuthModule" })
  public bioTrackTraceEnabled!: boolean;
  /**
   * Data to be manipulated.
   */
  public dataToSave: BatchAction<CustomBatch> | null = null;

  public isValid = false;

  public selectedRoom = 0;

  public disableQuantities = false;

  @Getter("rooms", { namespace: "ProductModule" })
  private rooms!: Room[];

  public created() {
    if (this.bioTrackTraceEnabled) {
      this.disableQuantities = true;
    }
    EventBus.$on("setMoveData", () => this.setData(this.data));
    this.setData(this.data);
  }

  public setData(data: BatchAction<CustomBatch>) {
    this.dataToSave = cloneDeep(data);
    this.updateTotals();
  }

  /**
   * Takes action on move click. Hits endpoint to
   * actually move the batches and updates the checks list.
   */
  public async onMove(valid: boolean) {
    if (Object.keys(this.dataToSave!.batches).length && valid) {
      const batchesArray = ungroup(this.dataToSave!.batches);
      this.$emit("summary", this.dataToSave);
      this.$emit("onMove", { toMove: batchesArray, toRoom: this.selectedRoom });
    }
  }

  public updateTotals() {
    const totals: CustomBatch[] = ungroup(this.dataToSave!.batches);
    this.dataToSave!.total_countable = sumObjArrayBy(totals, (t: CustomBatch) =>
      t.unit === PRODUCT_UNIT.UNIT_READABLE ? +t.room_quantity_editable : 0
    );
    this.dataToSave!.total_weighable = sumObjArrayBy(totals, (t: CustomBatch) =>
      t.unit !== PRODUCT_UNIT.UNIT_READABLE ? +t.room_quantity_editable : 0
    );
  }

  public onSetBatchSelected(batches: { [batchID: string]: CustomBatch[] }) {
    this.setData({
      ...this.data,
      batches: reduce(
        batches,
        (data: { [batchID: string]: CustomBatch[] }, value, key) => {
          const item = value.filter(i => i.selected);
          if (item.length) {
            data[key] = item;
          }
          return data;
        },
        {}
      )
    });
    this.updateTotals();
  }

  public onQuantityUpdate(formData: BatchQuantityManagerEvent<CustomBatch>) {
    this.isValid = formData.valid;
    if (formData.valid) {
      this.dataToSave = cloneDeep(formData.data);
    }
    // this.$emit("update", formData);
  }

  private onRoomChange(room: number) {
    this.selectedRoom = room;
  }
}
