import TraceabilityConfirm from "@/components/sharedComponents/traceabilityConfirm/TraceabilityConfirm.component";
import {
  BatchAction,
  CustomBatch,
  InventoryBatch,
  MovedProduct,
  PayloadMove
} from "@/interfaces/batch";
import { BatchOperation } from "@/interfaces/batchOperations";
import { Room } from "@/interfaces/room";
import { EventBus } from "@/internal";
import { i18n } from "@/plugins/i18n";
import { SecurityPinService } from "@/plugins/security-pin/security-pin.service";
import { batchOperationsService } from "@/services/batchOperations.service";
import { PageNavAction } from "@/types/types";
import { mapBatchesToAction } from "@/utils/batch-actions.utils";
import { ungroup } from "@/utils/convert.utils";
import { ActionsSubheaderComponent } from "helix-vue-components";
import cloneDeep from "lodash/cloneDeep";
import groupBy from "lodash/groupBy";
import pickBy from "lodash/pickBy";
import reduce from "lodash/reduce";
import { json } from "mathjs";
import Vue from "vue";
import Component from "vue-class-component";
import { Action, Getter } from "vuex-class";
import QuantitiesReservationComponent from "../common/QuantitiesReservation/QuantitiesReservation.component";
import Template from "./BatchMove.template.vue";
import MoveComponent from "./Move.component";
import { SummaryComponent } from "./summary/Summary.component";

@Component({
  mixins: [Template],
  components: {
    SummaryComponent,
    MoveComponent,
    reservation: QuantitiesReservationComponent
  }
})
export default class BatchMoveComponent extends Vue {
  public get isMovementsFinished() {
    if (Object.keys(this.formattedBatches!.batches).length) {
      return (
        this.formattedBatches &&
        !ungroup(this.formattedBatches!.batches).find(b => !b.newRoom)
      );
    }
    return false;
  }
  /**
   * Original batches data.
   */
  public batches: InventoryBatch[] | null = null;

  /**
   * Base batch structure. It works as a prop.
   * Once set in created lifecycle it must not change
   * since it's the only source of truth.
   */
  public data: BatchAction<CustomBatch> | null = null;

  /**
   * Moved batches to show in ListSelector.
   */
  public loading = false;
  public canReserve = true;
  public hasMovableBatches = false;
  public reservationData: BatchOperation | null = null;
  public reservedReady = false;
  public currentSummary: MovedProduct | null = null;
  public formattedBatches: BatchAction<CustomBatch> | null = null;
  public completeImmovableBatches: BatchAction<CustomBatch> | null = null;
  @Getter("bioTrackTraceEnabled", { namespace: "AuthModule" })
  public bioTrackTraceEnabled!: boolean;
  public movableBatches!: {
    [batchID: string]: CustomBatch[];
  };
  public immovableBatches!: {
    [batchID: string]: CustomBatch[];
  };

  public disableQuantities = false;

  @Getter("rooms", { namespace: "ProductModule" })
  private rooms!: Room[];
  @Action("setPageNav", { namespace: "PageNavModule" })
  private setPageNav!: PageNavAction;

  public created() {
    this.setPageNav({
      title: "batch_move.title",
      isLoading: () => this.loading,
      leftActions: {
        component: ActionsSubheaderComponent,
        props: {
          generalActions: [
            {
              visibleCondition: () => !this.reservedReady,
              icon: "fal fa-chevron-left",
              action: this.onBack,
              vuetifyProps: () => ({
                loading: this.loading,
                fab: true,
                small: true
              })
            }
          ]
        }
      },
      rightActions: {
        generalActions: () => [
          {
            visibleCondition: () => !this.reservedReady,
            action: this.reserveQuantities,
            buttonText: "batch_convert.reserve",
            vuetifyProps: () => ({
              disabled:
                this.loading ||
                !this.canReserve ||
                (!this.hasMovableBatches && this.bioTrackTraceEnabled),
              loading: this.loading
            })
          },
          {
            icon: "",
            action: this.onFinish,
            buttonText: "batch_move.finish",
            visibleCondition: () =>
              this.reservedReady && !this.isMovementsFinished,
            vuetifyProps: () => ({
              loading: this.loading
            })
          },
          {
            icon: "",
            action: this.onBack,
            buttonText: "batch_move.back",
            visibleCondition: () => !!this.isMovementsFinished,
            vuetifyProps: () => ({
              loading: this.loading
            })
          }
        ]
      }
    });
    this.batches = JSON.parse(this.$route.params.batches);
    this.formattedBatches = mapBatchesToAction(this.batches!);
    /**creating completeImmovableBatches with same data for the segregation
     * of movable and immovable batches in UI without disturbing much of the code
     */
    if (this.bioTrackTraceEnabled) {
      this.completeImmovableBatches = cloneDeep(this.formattedBatches);
      this.disableQuantities = true;
      const movableList: any[] = [];
      const immovableList: any[] = [];
      Object.keys(this.formattedBatches.batches).forEach((key: any) => {
        this.formattedBatches!.batches[key].map((item: any) => {
          if (item.quantity === item.room_quantity) {
            this.hasMovableBatches = true;
            this.movableBatches = groupBy(
              this.formattedBatches!.batches[key],
              "batch_uid"
            );
            movableList.push(this.movableBatches);
          } else {
            this.immovableBatches = groupBy(
              this.completeImmovableBatches!.batches[key],
              "batch_uid"
            );
            immovableList.push(this.immovableBatches);
          }
        });
      });
      this.formattedBatches.batches = Object.assign({}, ...movableList);
      this.completeImmovableBatches.batches = Object.assign(
        {},
        ...immovableList
      );
    }
  }

  public updateReservationData(e: {
    reservation: BatchAction<CustomBatch>;
    valid: boolean;
  }) {
    this.formattedBatches = e.reservation;
    this.canReserve = e.valid;
  }

  public updatePointer(formData: any) {
    this.formattedBatches = formData.data;
  }

  public async reserveQuantities() {
    this.loading = true;
    this.reservationData = await batchOperationsService.createOperation(
      this.formattedBatches!,
      "move"
    );
    if (this.reservationData) {
      this.formData();
    }
    this.loading = false;
  }

  public formData() {
    this.formattedBatches!.batches = pickBy(
      this.formattedBatches!.batches,
      item => !!item.filter(b => b.selected).length
    );
    this.data = cloneDeep(this.formattedBatches);
    this.reservedReady = true;
  }

  /**
   * Routes the user back to inventory-view.
   */
  public onBack() {
    this.$router.back();
  }

  /**
   * Takes user to final step.
   */
  public async onFinish() {
    this.loading = true;
    let noMoved = true;
    const resp = await batchOperationsService.finishMove(
      this.reservationData!.operation_uid
    );
    if (resp) {
      this.formattedBatches!.batches = reduce(
        this.formattedBatches!.batches,
        (batches: { [key: string]: CustomBatch[] }, value, key) => {
          batches[key] = value.filter(b => b.newRoom);
          if (noMoved) {
            noMoved = !batches[key].length;
          }
          return batches;
        },
        {}
      );

      if (noMoved) {
        this.formattedBatches = null;
        this.onBack();
      }
    }

    this.loading = false;
  }

  public setRooms(
    origin: { [key: string]: CustomBatch[] },
    batch: CustomBatch,
    toRoom: number
  ) {
    const indexOrigin = origin[batch.batch_uid!].findIndex(
      i => i.room_id === batch.room_id
    );
    origin[batch.batch_uid!][indexOrigin].newRoom = this.rooms.find(
      r => r.id === toRoom
    )!.name;
    return origin;
  }

  public updateQuantities(
    batches: { [key: string]: CustomBatch[] },
    batchChanged: CustomBatch
  ) {
    const index = batches[batchChanged.batch_uid!].findIndex(
      i => i.room_id === batchChanged.room_id
    );
    const currentBatch = batches[batchChanged.batch_uid!][index];
    currentBatch.room_quantity_editable =
      +currentBatch.room_quantity_editable -
      +batchChanged.room_quantity_editable;
    batches[batchChanged.batch_uid!][index] = currentBatch;
    const batchToShow = batches[batchChanged.batch_uid!].filter(
      i => i.room_quantity_editable
    );
    if (batchToShow.length) {
      batches[batchChanged.batch_uid!] = batchToShow;
    } else {
      delete batches[batchChanged.batch_uid!];
    }

    return batches;
  }
  /**
   * Takes action on move click. Hits endpoint to
   * actually move the batches and updates the checks list.
   */
  public updateSummary(data: any) {
    this.formattedBatches = data;
  }
  public async onMove(data: { toMove: CustomBatch[]; toRoom: number }) {
    this.loading = true;
    let changeBatches = cloneDeep(this.data!.batches);
    let batchesWinthRoom = cloneDeep(this.formattedBatches!.batches);
    const movements = data.toMove.reduce((items: PayloadMove[], item) => {
      changeBatches = this.updateQuantities(changeBatches, item);
      batchesWinthRoom = this.setRooms(batchesWinthRoom, item, data.toRoom);
      if (+item.room_quantity_editable!) {
        items.push({
          batch_uid: item.batch_uid || "",
          from_inventory_location_id: item.room_id,
          to_inventory_location_id: data.toRoom,
          quantity: +item.room_quantity_editable
        });
      }
      return items;
    }, []);
    const pinCode = await this.authorizeMove();
    if (pinCode) {
      if (this.bioTrackTraceEnabled) {
        this.renderTraceabilityModal(
          i18n.t("biotrack_traceability.duplicate_title").toString(),
          i18n
            .t("biotrack_traceability.moving_rooms_in_process_message")
            .toString(),
          i18n
            .t(
              "biotrack_traceability.moving_the_batch_in_biotrack_traceability_description"
            )
            .toString(),
          "loading",
          false,
          false,
          ""
        );
        const resp = await batchOperationsService.move(
          { movements },
          this.reservationData!.operation_uid,
          pinCode,
          undefined,
          this.bioTrackTraceEnabled
        );
        EventBus.$emit("removePopup", true);
        if (resp && !resp.status) {
          this.data!.batches = changeBatches;
          this.formattedBatches!.batches = batchesWinthRoom;
          EventBus.$emit("setMoveData");
        } else if (resp.status === "error") {
          this.renderTraceabilityModal(
            i18n.t("biotrack_traceability.move_batch_title").toString(),
            i18n.t("biotrack_traceability.move_batch_failed").toString(),
            i18n.t("biotrack_traceability.moving_batch_failed_description") +
              `${resp.data || resp.error.message}`.toString(),
            "",
            true,
            false,
            "Ok"
          );
        }
      } else {
        const resp = await batchOperationsService.move(
          { movements },
          this.reservationData!.operation_uid,
          pinCode
        );
        if (resp) {
          this.data!.batches = changeBatches;
          this.formattedBatches!.batches = batchesWinthRoom;
          EventBus.$emit("setMoveData");
        }
      }
    }
    this.loading = false;
  }

  protected async renderTraceabilityModal(
    titleName: string,
    statusName: string,
    descriptionName: string,
    loader: string,
    accept: boolean,
    cancel: boolean,
    acceptValue: string
  ) {
    this.$modals.load(
      TraceabilityConfirm,
      { size: "normal", positionX: "center", positionY: "center" },
      {
        modalData: {
          status: {
            name: this.$t(statusName),
            style: "fontSize:27px ; fontWeight:600;"
          },
          description: {
            name: this.$t(descriptionName),
            style: "fontSize:23px ;"
          },
          title: {
            name: this.$t(titleName),
            style: "fontSize:30px ; letter-spacing: 0px;"
          },
          titleAvatar: {
            name: "/img/icon_primary_menu_inventory@2x.9f2161a2.png",
            size: 80
          },
          loading: this.$t(loader),
          acceptButton: accept,
          cancelButton: cancel,
          acceptButtonValue: acceptValue
        }
      }
    );
  }

  private async authorizeMove() {
    const pin$ = new SecurityPinService();
    return pin$
      .ensure("security_pin.title", {
        text: "security_pin.description"
      })
      .then(
        pin => {
          return pin;
        },
        () => {
          EventBus.$emit("notify", {
            icon: "fas fa-exclamation-circle",
            text: "security_pin.required_message",
            color: "error"
          });
          return "";
        }
      );
  }
}
