import { CustomBatch } from "@/interfaces/batch";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { Getter } from "vuex-class";
import Template from "./MovedList.template.vue";
const NO_DATA = "--";

@Component({
  mixins: [Template]
})
export class MovedListComponent extends Vue {
  /**
   * Moved batches to iterate.
   */
  @Prop({ required: true })
  public movedBatches!: CustomBatch[];

  /**
   * If true, sets the summary and emits it when mounted.
   */
  @Prop({ default: false }) public emitOnInit!: boolean;
  @Prop({ default: false }) public fullHeight!: boolean;
  @Getter("hasBioTrackTraceIntegrations", { namespace: "AuthModule" })
  public hasBioTrackTraceIntegrations!: boolean;
  @Getter("bioTrackTraceEnabled", { namespace: "AuthModule" })
  public bioTrackTraceEnabled!: boolean;
  private currentIndex: number | null = null;

  private expanded = true;

  public mounted() {
    if (this.emitOnInit) {
      this.onMovedItemClick(0);
    }
  }

  /**
   * When element is clicked, emits the summary object.
   * @param index: number
   */
  private onMovedItemClick(index: number) {
    this.currentIndex = index;
    if (this.movedBatches.length) {
      const currentBatch = this.movedBatches[index];
      const currentSummary = {
        product: currentBatch.product_name,
        strain: currentBatch.strain || NO_DATA,
        batch_type: currentBatch.batch_type || NO_DATA,
        batch_uid:
          this.bioTrackTraceEnabled &&
          currentBatch.biotrack_traceability_id !== ""
            ? currentBatch.biotrack_traceability_id
            : currentBatch.batch_uid,
        origin_room: currentBatch.room_name || NO_DATA,
        new_room: currentBatch.newRoom || NO_DATA,
        usable_weight: currentBatch.usable_weight_value
          ? `${currentBatch.usable_weight_value} ${
              currentBatch.usable_weight_unit
            }`
          : NO_DATA,
        quantity: `${currentBatch.room_quantity_editable} ${currentBatch.unit}`
      };

      this.$emit("showSummary", currentSummary);
    }
  }

  private get direction(): string {
    return this.expanded ? "down" : "right";
  }

  private onExpand() {
    this.expanded = !this.expanded;
  }
}
