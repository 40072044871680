import { MovedListComponent } from "@/components/batch/move/MovedList/MovedList.component.ts";
import { BatchAction, CustomBatch, MovedProduct } from "@/interfaces/batch";
import { ungroup } from "@/utils/convert.utils";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import Template from "./Summary.template.vue";

@Component({
  mixins: [Template],
  components: {
    MovedListComponent
  }
})
export class SummaryComponent extends Vue {
  /**
   * Moved batches to iterate.
   */
  @Prop({ required: true })
  public origin!: BatchAction<CustomBatch>;
  public currentSummary: MovedProduct | null = null;

  private onShowSummary(product: MovedProduct) {
    this.currentSummary = product;
  }

  public get previewMove() {
    return (
      (this.origin && ungroup(this.origin!.batches).filter(b => b.newRoom)) ||
      null
    );
  }
}
